/* eslint-disable */

import styled from '@emotion/styled';

import Search from './SVGs/Search';

export const AvatarStyled = styled.img`
  float: left;
  min-width: 130px;
  min-height: 130px;
  margin-right: 20px;
`;
export const NameStyled = styled.h1`
  margin-top: 0;
  margin-bottom: 1em;

  color: ${props => props.theme.colorDarkGray};
  font-size: 14px;
  line-height: 16px;
`;
export const MemberCardStyled = styled.article`
  width: 100%;
  margin-top: 3rem;

  overflow: hidden;

  background: ${props => props.theme.colorWhite};
  border: 1px solid ${props => props.theme.colorDisabled};

  @media (max-width: 991.98px) {
    ${AvatarStyled} {
      width: auto;
      min-width: initial;
      min-height: initial;
      max-height: 37px;
    }

    ${NameStyled} {
      margin-bottom: 4px;
    }
  }
`;
export const CardSliderStyled = styled.div`
  position: relative;

  display: flex;

  flex-grow: 1;
  flex-direction: row;
  align-items: stretch;
  width: 200%;
  height: 100%;
  overflow: hidden;
`;
export const TagsStyled = styled.div`
  position: relative;

  height: ${props => (props.open ? 'auto' : '27px')};
  margin: 0;
  overflow: hidden;
`;

export const BioStyled = styled.p`
  display: ${props => (props.display ? 'block' : 'none')};
  flex: 1;

  margin: 1em 4px 0 4px;

  color: ${props => props.theme.colorHint};
  text-align: justify;
`;

export const CardInfoStyled = styled.div`
  position: relative;
  left: 0;

  flex-direction: column;
  align-items: flex-start;

  width: calc(50% - 70px);
  padding: 20px;
  overflow: hidden;

  font-size: 12px;
  line-height: 14px;

  transition: left 0.5s;

  @media (max-width: 991.98px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 767.98px) {
    padding: 15px;
  }
   @media (max-width: 575.98px) {
    padding: 5px;
  }
`;

export const MembershipStatusStyled = styled.span`
  float: right;

  text-transform: uppercase;
`;
export const TagStyled = styled.span`
  display: inline-block;
  margin: 3px 8px 3px 0;
  padding: 3px 8px;

  color: ${props => props.theme.colorDarkGray};
  line-height: 1;

  background: ${props => props.theme.colorDisabled};
  border-radius: 4px;

  ${props =>
    props.dark &&
    `
    background: ${props.theme.colorDarkGray};
    color: ${props.theme.colorWhite};
  `}
`;

export const ContactItemStyled = styled.p`
  display: flex;
  margin: 5px 10px 5px 0;

  color: ${props => props.theme.colorHint};
  svg {
    width: 20px;
    margin-right: 13px;

    vertical-align: middle;
  }
  a {
    color: ${props => props.theme.colorHint};
  }
`;

export const ArrowBtnStyled = styled.button`
  position: relative;

  left: ${props => (props.right ? 'calc(-50% + 70px)' : '0')};

  margin: 0;
  padding: 0 30px;

  background: ${props => props.theme.colorBtnBg};
  border: none;

  outline: none;

  transform: rotate(${props => (props.right ? '180deg' : '0')});
  cursor: pointer;

  transition: left 0.5s;
  :before {
    content: '';

    display: block;
    width: 0;
    height: 0;

    border-width: 11px 5px;
    border-style: solid;
    border-color: transparent ${props => props.theme.colorBtnText} transparent
      transparent;
  }

  @media (max-width: 991.98px) {
    left: ${props => (props.right ? 'calc(-50% + 30px)' : '0')};

    padding: 0 12px 0 8px;
  }
`;

export const AdminLinksStyled = styled.div`
  width: 15%;
  padding: 20px 10px;

  font-size: 12px;
  line-height: 2;

  text-align: right;
  text-transform: uppercase;

  background: ${props => props.theme.colorBtnBg};
  a {
    display: block;

    color: ${props => props.theme.colorBtnText};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colorBtnHover};
    }
  }
`;

export const TagArrowStyled = styled.button`
  position: absolute;
  top: 3px;
  right: 0;

  width: 18px;
  height: 18px;

  padding: 0;

  color: ${props => props.theme.colorWhite};
  line-height: 1;
  text-align: center;
  vertical-align: middle;

  background-color: ${props => props.theme.colorActive};
  border: none;
  border-radius: 50%;
  outline: none;
  transform: rotate(${props => (props.open ? '270' : '90')}deg);
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colorActiveHover};
  }
`;

export const ArrowStyled = styled.button`
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 5px;
  padding: 0;

  vertical-align: middle;

  background: none;
  border-width: 5px;
  border-style: solid;
  border-color: transparent ${props => props.theme.colorDarkGray} transparent
    transparent;

  outline: none;
  cursor: pointer;

  ${props => (props.right ? 'transform: rotate(180deg);' : '')}

  &[disabled] {
    border-right-color: ${props => props.theme.colorDisabled};
  }
`;
export const PaginationStyled = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
  margin-bottom: 15px;
  padding: 5px 10px;

  background-color: ${props => props.theme.colorWhite};
`;
export const PagesStyled = styled.div`
  color: ${props => props.theme.colorDarkGray};
  line-height: 1em;
`;

export const CloseBtnStyled = styled.button`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  padding: 0;

  color: ${props => props.theme.colorWhite};
  line-height: 12px;
  vertical-align: middle;

  background: ${props => props.theme.colorNegative};
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  svg {
    position: relative;
    top: -1px;

    display: inline-block;
  }
`;

export const SelectionTagStyled = styled.span`
  display: inline-block;
  margin: 5px;
  padding: 4px 10px;

  font-size: 10px;
  line-height: 14px;
  vertical-align: middle;

  background-color: ${props => props.theme.colorDisabled};

  border-radius: 10px;

  ${CloseBtnStyled} {
    margin-right: 8px;
  }

  > svg {
    position: relative;
    top: -1px;

    vertical-align: text-bottom;
  }
`;

export const FilteredListContainerStyled = styled.aside`
  padding: 15px;
  overflow: hidden;

  background: ${props => props.theme.colorOffWhite};

  @media (max-width:  991.98px) {
    padding: 0;
  }
`;

const showBox = (props, margin) => `
  height: ${props.height};
  margin: ${margin};
`;

const hideBox = margin => `
  height: 0;
  margin: ${margin};
`;
export const FilterListStyled = styled.div`
  margin: 20px 0 20px 15px;
  overflow-y: auto;

  transition: 0.5s;

  @media (min-width: 991.98px) {
    ${props => !props.open && hideBox('0 15px')}
    ${props => props.open && showBox(props, '20px 0 20px 15px')}
  }
  @media (max-width: 991.98px) {
    ${props => !props.open && showBox(props, '20px 0 20px 15px')}
    ${props => props.open && hideBox('0 15px')}
  }
`;

export const FilterListTitleStyled = styled.button`
  width: 100%;
  padding: 10px 20px;

  color: ${props => props.theme.colorBtnText};
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;

  background-color: ${props => props.theme.colorBtnBg};
  border: none;
  border-bottom: 1px solid ${props => props.theme.colorWhite};

  outline: none;
  cursor: pointer;

  transition: 0.2s;

  &:hover {
    background-color: ${props => props.theme.colorBtnHover};
  }
`;

export const IndicatorStyled = styled.span`
  float: right;

  color: ${props => props.theme.colorWhite};

  :before {
    content: "${props => (props.toggle ? '\\2013' : '\\2b')}";

    @media(max-width: 991.98px) {
       content: "${props => (!props.toggle ? '\\2013' : '\\2b')}";
    }

    display: block;

    transition: 0.5s;
  }
`;

export const SearchContainerStyled = styled.div`
  position: relative;

  flex: 1;

  max-width: 330px;
`;

export const SearchIconStyled = styled(Search)`
  position: absolute;
  top: 8px;
  left: 15px;
`;

export const SearchInputStyled = styled.input`
  width: 100%;
  padding: 9px 40px;

  font-size: 12px;
  text-align: center;

  background: ${props => props.theme.colorOffWhite};
  border: 1px solid ${props => props.theme.colorDisabled};
  border-radius: 20px;
  ::placeholder {
    color: ${props => props.theme.colorHint};
    text-transform: uppercase;
  }
  :focus {
    outline: none;
  }
`;

export const LabelStyled = styled.label`
  position: relative;

  display: block;
  width: 100%;
  margin: 10px auto;

  color: ${props => props.theme.colorHint};

  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: middle;

  cursor: pointer;

  user-select: none;
`;

export const CheckBoxInputStyled = styled.input`
  position: absolute;
  left: -30px;

  width: 0;

  height: 0;
  margin: 0;

  opacity: 0;
  &:checked ~ span {
    background-color: ${props => props.theme.colorDarkGray};
  }
`;

export const CheckBoxCheckStyled = styled.span`
  display: inline-block;
  width: 14px;

  height: 14px;
  margin: 0 10px 3px 0;

  line-height: 20px;
  vertical-align: middle;

  border: 1px solid ${props => props.theme.colorDarkGray};
  content: '';
`;

export const WebsiteLinkStyled = styled.a`
  float: right;

  color: ${p => p.theme.colorActive};
  text-transform: uppercase;
  text-decoration: none;

  border-bottom: 1px solid currentColor;
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${p => p.theme.colorActiveHover};
  }
`;

export const SelectionBoxStyled = styled.div`
  overflow: hidden;

  transition: 0.5s;
  @media (min-width: 991.98px) {
    ${props => !props.open && hideBox('0')}
    ${props => props.open && showBox(props, '10px 0')}
  }
  @media (max-width: 991.98px) {
    ${props => !props.open && showBox(props, '10px 0')}
    ${props => props.open && hideBox('0')}
  }
`;

export const AdminDirectoryCardStyled = styled(MemberCardStyled)`
  display: flex;
  ${CardInfoStyled} {
    width: 85%;
  }
  @media (max-width: 991.98px) {
    flex-direction: column;
    height: auto;
    ${MembershipStatusStyled} {
      display: block;
      float: none;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    ${CardInfoStyled} {
      width: 100%;
    }
    ${AdminLinksStyled} {
      width: 100%;
      padding: 0 15px;

      text-align: center;
      div {
        display: inline-block;
      }
      a {
        display: inline-block;
        margin: 10px 15px;
      }
    }
  }
`;
